
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Prop, Vue } from 'nuxt-property-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import carTaxCard from '@pp-frontend/car-tax-widget/components/carTaxCard.vue'
import Banner from '~/components/Banner.vue'
import BannerNewApp from '~/components/BannerNewApp.vue'
import SearchGuarantees from '~/components/SearchGuarantees.vue'

@Component({
  components: {
  SearchGuarantees,
  carTaxCard,
  Banner,
  BannerNewApp,
  guarantees: () => import('@pp-frontend/ui-kit/components/common/guarantees.vue'),
  Features: () => import('./Features.vue')
  }
  })
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class container extends Vue {
  @Prop() isHideSidebar!: boolean
  @Prop() isShowBanner!: boolean
  type: string = 'gibdd'
  allowServices: any = this.$allowServices()
  customStyles: boolean = false
  texts: Object = {
    gibdd: {
      guarantee: {
        title: 'Гарантия погашения',
        text: 'Данные об оплате автоматически передаются в базу данных ГИБДД через Федеральное Казначейство'
      },
      data: {
        title: 'Официальные данные',
        text: 'Поиск задолженностей осуществляется в базах данных Федерального казначейства и Государственной автоинспекции'
      },
      payment: {
        title: 'Моментальное погашение',
        text: 'Платежное поручение бесплатно отправляется на вашу электронную почту сразу после оплаты'
      },
      payment_type: {
        title: 'Безопасные платежи',
        text: 'Безопасность обеспечивается сквозным TLS-шифрованием и сертифицирована по стандарту PCI DSS'
      }
    },
    fns: {
      guarantee: {
        title: 'Гарантия погашения',
        text: 'Данные об оплате автоматически передаются в базу данных ФНС через Федеральное Казначейство'
      },
      data: {
        title: 'Официальные данные',
        text: 'Поиск задолженностей осуществляется в базах данных Федерального казначейства (ГИС ГМП)'
      },
      payment: {
        title: 'Моментальное погашение',
        text: 'Платежное поручение бесплатно отправляется на вашу электронную почту сразу после оплаты'
      },
      payment_type: {
        title: 'Безопасные платежи',
        text: 'Безопасность обеспечивается сквозным TLS-шифрованием и сертифицирована по стандарту PCI DSS'
      }
    },
    fssp: {
      guarantee: {
        title: 'Гарантия погашения',
        text: 'Данные об оплате автоматически передаются в базу данных ФССП России через Федеральное Казначейство'
      },
      data: {
        title: 'Официальные данные',
        text: 'Поиск задолженностей осуществляется в базах данных Федерального казначейства и судебных приставов'
      },
      payment: {
        title: 'Моментальное погашение',
        text: 'Платежное поручение бесплатно отправляется на вашу электронную почту сразу после оплаты'
      },
      payment_type: {
        title: 'Безопасные платежи',
        text: 'Безопасность обеспечивается сквозным TLS-шифрованием и сертифицирована по стандарту PCI DSS'
      }
    }
  }

  showDefaultFeatures () {
    if (!this.$route.name) {
      return true
    }
    if (this.$route.name === 'email') {
      this.customStyles = true
      return false
    }
    // @ts-ignore
    // eslint-disable-next-line no-mixed-operators
    if (!this.$route?.name.includes('search') && !this.$route?.name.includes('index') || this.$route.path.includes('widget')) {
      return true
    }
  }

  mounted () {
    // @ts-ignore
    if (this.$route?.query?.payment_type) {
      // @ts-ignore
      this.type = this.$route?.query?.payment_type || 'gibdd'
    }
    const types = {
      index: 'gibdd',
      'index-fns': 'fns',
      'index-gibdd': 'gibdd',
      'index-fssp': 'fssp',
      'search-gibdd': 'gibdd',
      'search-fns-esia': 'fns',
      'search-fns': 'fns',
      'search-fssp': 'fssp',
      email: 'gibdd'
    }
    // @ts-ignore
    this.type = types[this.$route.name]
  }
}
